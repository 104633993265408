<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Criar Usuário
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nome Completo"
            rules="required"
          >
            <b-form-group
              label="Nome Completo"
              label-for="nome-completo"
            >
              <b-form-input
                id="nome-completo"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe um nome"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Função"
            rules="required"
          >
            <b-form-group
              label="Função dentro da Empresa"
              label-for="funcao"
            >
              <b-form-input
                id="funcao"
                v-model="userData.funcao"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe a função "
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="setor"
            rules="required"
          >
            <b-form-group
              label="Setor Atual"
              label-for="setor"
            >
              <v-select
                id="setor"
                v-model="userData.setor"
                :options="listaSetores"
                label="nome"
                :reduce="val => val.value"
                :clearable="false"
                :state="getValidationState(validationContext)"
                input-id="user-setor"
                placeholder="Escolha um setor"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="classes"
          >
            <b-form-group
              label="Classe"
              label-for="classes"
            >
              <select-com-pesquisa
                id="classes"
                v-model="userData.classes"
                name="classes"
                url="/classes"
                placeholder="Escolha a classe"
                :multiple="true"
                :disabled="false"
                :itens-para-edicao="[]"
                @selecionados="tratarEventoClassesSelecionadas"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>
          <!-- Telefone -->
          <validation-provider
            #default="validationContext"
            name="Telefone"
            rules="required"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                ref="telefoneInput"
                v-model="userData.telefone"
                v-mask="'(##) # ####-####'"
                :state="getValidationState(validationContext)"
                trim
                placeholder="(00) 0 0000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Data Nascimento -->
          <validation-provider
            #default="validationContext"
            name="Data Nascimento"
            rules="required"
          >
            <b-form-group
              label="Data Nascimento"
              label-for="data_nascimento"
            >
              <b-form-input
                id="data_nascimento"
                v-model="userData.data_nascimento"
                type="date"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Perfil"
            rules="required"
          >
            <b-form-group
              label="Perfil"
              label-for="perfil"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
                placeholder="Escolha um perfil"
                multiple
              >
                <template v-slot:no-options>
                  Nenhuma opção encontrada
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Empresa -->
          <validation-provider
            #default="validationContext"
            name="Empresa"
            rules="required"
          >
            <b-form-group
              label="Empresa"
              label-for="empresa"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.empresas"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="empresaOptions"
                label="nome"
                :reduce="val => val.uuid"
                :clearable="false"
                input-id="user-empresa"
                placeholder="Escolha uma empresa"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { alphaNum, required } from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    SelectComPesquisa,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    empresaOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      roles: null,
      telefone: null,
      data_nascimento: null,
      empresas: [],
      classes: [],
    }

    // Use toast
    const toast = useToast()

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const listaSetores = computed(() => store.state.app.listaSetores)

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário adicionado com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      listaSetores,

    }
  },
  methods: {
    tratarEventoClassesSelecionadas(itens) {
      this.userData.classes = itens || {}
      store.commit('app-user/alterarDadosUsuario', this.userData)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
